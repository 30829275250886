import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "src/layout"
import { Column, Title, Paragraph } from "src/layout/styled"

const Card = styled(Paragraph)`
  a {
    text-decoration: none;
  }
`

interface Data {
  contacts: { nodes: Contact[] }
}

const ContactPage: GatsbyPage<Data> = ({ data }) => {
  return (
    <Layout theme="dark" title="Nous contacter">
      <Column>
        <Title>Nous contacter</Title>
        <Paragraph>Nous sommes à votre disposition pour discuter de vos projets et besoins.</Paragraph>
        <Paragraph>
          Sollicitez-nous pour toute demande de renseignements, d’interventions, ou tout simplement pour nous rencontrer
          et échanger.
        </Paragraph>
        {data.contacts.nodes.map(({ id, name, job, email, phone }) => (
          <Card key={id}>
            <strong>{name}</strong>, {job}
            <br />
            <a href={`mailto:${email}`}>{email}</a>
            <br />
            {phone && <a href={`tel:${phone.replace(/\s+/g, "").replace(/^0/, "+33")}`}>{phone}</a>}
          </Card>
        ))}
        <Title>Nous rejoindre</Title>
        <Paragraph>
          <Link to="/rejoindre/">Nos offres de stages et d’emploi</Link>
        </Paragraph>
      </Column>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    contacts: allContentfulContact(sort: { fields: position }) {
      nodes {
        id: contentful_id
        name
        job
        email
        phone
      }
    }
  }
`
